import { Injectable, signal } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  private connectionStatus: Subject<string> = new Subject<string>();
  employeeId: string;
  userStatus  =  '';
  userStatus$  =  new Subject() ;
  socketId$ =  new BehaviorSubject('');
  statusEnum  = [ 'online' , 'offline' , 'onBreak' , 'onLeave' ];
  currentUser : any ;
  

  constructor() {
   
  }
  setConnection(): void {
    this.disconnect();
    this.socket = io('https://dev.reporting.visionway-dev.com', {
      transports: ["polling"]
    });

    this.socket.on('connect', () => {
      //  this.connectionStatus.next('connected');  
       this.socketId$.next(this.socket.id);
      const currentUserString = localStorage.getItem('currentUser');
      const currentUser: any = currentUserString ? JSON.parse(currentUserString) : null;
      if (currentUser) {
          this.currentUser =  currentUser ;
          this.employeeId = currentUser['employeeId'];
          this.userStatus  =   localStorage.getItem('userStatus');
          this.userStatus$.next(this.userStatus);
          if(!this.userStatus || (this.userStatus == 'offline')){
           // console.log("user is  online or not" , this.userStatus);
              this.userStatus = 'online';
              this.userStatus$.next('online');
          }
          //console.log("user status " , this.userStatus);
          this.emit('add-user', { userId: this.employeeId  , companyId : this.currentUser.companyId });  
          this.emit('update-status' , {userId : this.employeeId , employeeId : this.employeeId , status: this.userStatus  ,  socketId:  this.socket.id , companyId : this.currentUser.companyId });
      }
    });
    // Handle connection errors
    this.socket.on('connect_error', (error: any) => {
      console.error('Connection Error:', error.message);
    });

   
    this.socket.on('error', (error: any) => {
      console.error('Socket Error:', error.message);
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from the server 1');
      //  this.connectionStatus.next('disconnected'); 
    });
  }
  emit(event: string, data: any): void {
    // if(data['status'] &&  this.statusEnum.includes(data['status'])){
   // console.log("event emit is " , event , data , this.socket.id);
        this.socket.emit(event, data );
     //} 
  }
   
  listen(event: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(event, (data) => {
        subscriber.next(data);
      });
      return () => {
        this.socket.off(event);
      };
    });
  }

  getConnectionStatus(): Observable<string> {
    return this.connectionStatus.asObservable();
  }

  disconnect(): void {
   
    if (this.socket) {
     // console.log('socket destroyed');

      this.socket.disconnect();
     
    }else {
     // console.log('socket not found ');

    }
  }
}
