import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { init } from 'ys-webrtc-sdk-ui';

@Injectable({ providedIn: 'root' })
export class PbxDiallerService {
    
    phone: any;
    userName:string;
    sign:string;
    destroyInstance: () => void; // Store the destroy function
    constructor(private http: HttpClient) {
    
     }
    checkPBK(): void {
        this.destroyPBXSDK();
        setTimeout(()=>{
              this.initPBXSDK();
        } , 0 )
        
    }
    initPBXSDK() {
        this.destroyPBXSDK();
       
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.userName = currentUser.officialEmail
        this.sign = currentUser.ivr_signature ;
        const container = document.getElementById('callingdialler');
        // Initialization
        init(container, {
            username: this.userName,
            secret: this.sign,
            pbxURL: 'https://visionapplyivr.ras.yeastar.com',
            sessionOption: {'sessionSetting' : {
                x: '10',
                y: '40',
                video: true, 
            }},
            video: true
        }).then(data => {
        // Obtain the exposed instances for additional business needs
        const { pbx, destroy, on } = data;
        this.phone = data.phone
        this.destroyInstance = destroy;
        // Create an RTC instance.
        this.phone.on('newRTCSession', ({callId,session})=>{
          const {status} = session
    
          // Listen for events in the session.
          session.on('confirmed', ({callId,session})=>{
              // A call is successfully connected, the 'session.status.callStatus' changes to 'talking'.
              // Update the user interface to start the call timer.
          })
        })
    
        // Listen for the 'startSession' events.
        this.phone.on('startSession',({callId,session})=>{
            const {status} = session
            if(status.communicationType === 'outbound') {
                // Outbound call.
                // Update the user interface to display 'Calling', indicating the callee side is ringing.
            }else{
                // Inbound call.
                // Update the user interface to display 'Connecting'.
            }
    
        });
    
        // Listen for Incoming call events.
        this.phone.on('incoming', (callId,session)=>{
            const {status} = session
            // Pop up an incoming call dialog displaying the caller's phone number and contact name on the User interface.
            // ...
            // Click the 'Answer' button to trigger the 'answer' method and the 'startSession' event.
            this.phone.answer(status.number); 
        });
    
        // After events subscription, start connecting to the SIP UA.
        this.phone.start();
      }).catch(err=>{
            console.log(err);
        })
    }
    destroyPBXSDK() {
        if (this.destroyInstance) {
            this.destroyInstance();

            //console.log('PBX instance destroyed');
        } else {
            //console.warn('Destroy instance is not available');
        }
    }
}
