import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { changesLayout } from "src/app/store/layouts/layout.actions";
import { getLayoutMode } from "src/app/store/layouts/layout.selector";
import { RootReducerState } from "src/app/store";
import { getUser } from "src/app/store/Authentication/authentication-selector";
import { IApiResponse } from "src/app/services/api/models";
import { HttpServiceNode } from "src/app/services/api/api.service";
import { NotificationService } from "src/app/pages/notify-module/notification.service";
import { PbxDiallerService } from "src/app/core/services/pbx-dialling.service";
import { SocketService } from "src/app/services/api/common-services/socket.service";
import { checkEmpData, fetchEmployeeData } from "src/app/store/employee/employee.action";
import Swal from 'sweetalert2'; 
import { PesonalChatSocketService } from "src/app/services/api/common-services/personalchat.socket.service";






@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {
  mode: any;
  totalNotifications: number = 0;
  totalNotifications1: any;
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;
  user$: Subscription;
  phone: any;
  employeeState$;
  // Define layoutMode as a property
  employeeData: any = null;
  statusSelectObj = [
    { name: 'Online', val: 'online' },
    { name: 'Offline', val: 'offline' },
    { name: 'On Break', val: 'onBreak' },
    { name: 'On Leave', val: 'onLeave' },
  ];

  currentUserStatus: string = 'online';
  currentUserStatusName: string = 'Online';
  statusClass = {
    online: 'text-success',
    offline: 'text-danger',
    onBreak: 'text-info',
    onLeave: 'text-warning'
  };
  socketId = '';



  constructor(
    @Inject(DOCUMENT) private document: any,
    private _apiService: HttpServiceNode,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private notificationService: NotificationService,
    public store: Store<RootReducerState>,
    private _pbxDiallerService: PbxDiallerService,
    private _socketClient: SocketService,
    private _chatSocket: PesonalChatSocketService
  ) {

  
    this.employeeData = JSON.parse(localStorage.getItem('currentUser'));
   // console.log("emp ", this.employeeData)

   this.getCountLevel();
   
  }

  listLang: any = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();


  ngOnInit() {
    // this.initialAppState = initialState;
  
    this.store.select("layout").subscribe((data) => {
      this.theme = data.DATA_LAYOUT;
    });
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.user$ = this.store.select(getUser).subscribe((data) => {
      //console.log(data, "data");
    });
    this.notificationService.notificationCount$.subscribe((count) => {

      this.totalNotifications1 = count;
      this.getCountLevel();
    });

    const callingDialler = document.getElementById('callingdialler');
    if (this.employeeData.ivr_signature) {
      this._pbxDiallerService.checkPBK();
      callingDialler.style.display = 'block';
    } else {
       callingDialler.style.display = 'none';
    }

    this._socketClient.userStatus$.subscribe((userStatus: string) => {
      if (userStatus) {
        this.currentUserStatus = userStatus;
        this.statusSelectObj.some(item => {
          if (item.val == userStatus) {
            this.currentUserStatusName = item.name;
            return true;
          }
          return false;
        });
      }
    });

    this._socketClient.socketId$.subscribe((id: any) => {
         //console.log("my socket id is" , id);
        this.socketId = id;
    });

    this._socketClient.listen('force_logout').subscribe((data: any) => {
             //console.log("logout ", data)
             Swal.fire('Authentication Failed!', 'Another user has logged in with the same credentials.', 'error');
             this.logout();
    });
    this.store.dispatch(fetchEmployeeData());
    this._chatSocket.setConnection();
   
  }


  updateCurrentStatus(status: { name: string, val: string }): void {
    localStorage.setItem('userStatus', status.val);
    this.currentUserStatus = status.val;
    this.currentUserStatusName = status.name;
    this._socketClient.userStatus$.next( status.val);
    this._socketClient.emit('update-status', { userId: this.employeeData['employeeId'], employeeId: this.employeeData['employeeId'], status: this.currentUserStatus , socketId : this.socketId , companyId: this.employeeData.companyId  });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
 

  /**
   * Logout the user
   */
  logout() {
   localStorage.setItem('userStatus', '');
    if (environment.defaultauth === "firebase") {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(["/auth/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.store.select(getLayoutMode).subscribe((layout) => {
      document.documentElement.setAttribute("data-layout", layout);
    });
  }
  gotoNotify() {
    this.router.navigate(["/notification"]);
  }
  getCountLevel(): void {

    let currentUser = localStorage.getItem("currentUser");
    let empId = currentUser ? JSON.parse(currentUser).employeeId : null;

    const url = "notification/notification/counts";
    const formData = {
      employeeId: empId,
    };

    this._apiService.get(url, formData).subscribe({
      next: (response: any) => {

        if (response) {
          this.totalNotifications = response.total;
        }
      },
      error: (err: IApiResponse<any>) => {
        console.error("Error fetching notification counts", err);
      },
    });
  }
  ngOnDestroy(): void {
    
    // // this._socketClient.userStatus$.unsubscribe();
  }
}
